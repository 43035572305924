import { Controller } from 'stimulus';
import { errorNotification } from '../components/notifications';

export default class extends Controller {
  prefillEmail(event) {
    const blob = JSON.parse(event.currentTarget.dataset.blobJson);
    const filename = blob.filename;
    const key = blob.key;
    const file = new File([blob], filename);
    const emailData = {
      attachments: [{ file, key }],
      subject: 'Sharing a file with you'
    };

    window.dispatchEvent(new CustomEvent('emailModalData', { detail: emailData }));
  }

  handleShowRequestSignatureModal(event) {
    if(this.prefillSignature(event)) {
      window.dispatchEvent(new CustomEvent('showRequestSignatureModal'));
    }
  }

  prefillSignature(event) {
  const supportedFormats = ['pdf', 'docx', 'txt', 'png', 'jpg', 'ppt'];
  const blob = JSON.parse(event.currentTarget.dataset.blobJson);
  const blob_url = event.currentTarget.dataset.blobUrl;
  const file_extension = blob.filename.split('.').pop().toLowerCase();

  if (!supportedFormats.includes(file_extension)) {
    errorNotification(`Unsupported file format: ${file_extension}`);
    return false;
  }

  const signatureData = {
    attachments: [{
      attachment_id: blob.id,
      file_id: blob.id,
      file_name: blob.filename,
      file_task_name: 'Request signature',
      file_upload_date: blob.created_at,
      file_url: blob_url
    }],
    subject: 'Signing a document'
  };

  window.dispatchEvent(new CustomEvent('signatureModalData', { detail: signatureData }));
  return true;
}
}
