import Noty from 'noty';
import axios from 'axios';
import { Controller } from 'stimulus';
import Logger from '../mixins/logger';
import { infoNotification, errorNotification } from '../components/notifications';

export default class extends Controller {
  static targets = ['delete', 'download', 'checkbox', 'share', 'signature'];

  initialize() {
    this.toggle = this.toggle.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  connect() {
    this.checkboxTargets.forEach(checkbox => checkbox.addEventListener('change', this.refresh));
    this.refresh();
  }
  disconnect() {
    this.checkboxTargets.forEach(checkbox => checkbox.removeEventListener('change', this.refresh));
  }

  toggle(e) {
    e.preventDefault();
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = e.target.checked;
    });
  }

  refresh() {
    /*
      show/hide the delete button depending on the selection
     */
    const checkboxesCheckedCount = this.checked.length;
    if (checkboxesCheckedCount > 0) {
      this.showSelectionActions();
    } else {
      this.hideSelectionActions();
    }
  }

  get checked() {
    return this.checkboxTargets.filter(checkbox => checkbox.checked);
  }

  destroySelected(event) {
    event.preventDefault();
    this.confirmDelete();
  }

  downloadSelected(event) {
    event.preventDefault();
    this.submitDownloadForm();
  }

  showSelectionActions() {
    this.deleteTarget.classList.remove('u-hidden');
    this.downloadTarget.classList.remove('u-hidden');
    this.shareTarget.classList.remove('u-hidden');
    this.signatureTarget.classList.remove('u-hidden');
  }

  hideSelectionActions() {
    this.deleteTarget.classList.add('u-hidden');
    this.downloadTarget.classList.add('u-hidden');
    this.shareTarget.classList.add('u-hidden');
    this.signatureTarget.classList.add('u-hidden');
  }

  confirmDelete() {
    const { confirmTitle, confirmMessage } = this.element.dataset;
    const message = `<h1 class="c-heading-1 u-color-gris-dark u-mb-large"><span class='u-color-danger'>${ confirmTitle || '' }</span></h1>
                     <p class="u-ts-3 u-mb-double u-color-gris-dark">${ confirmMessage || '' }</p>`;

    const buttons = [
      Noty.button('Cancel', 'c-button c-button--neutral c-button--large u-mr', () => {
        notyInstance.close();
      }),

      Noty.button('Confirm', 'c-button c-button--primary c-button--large u-mr', () => {
        const data = new FormData();
        this.checked.forEach((checkbox) => data.append('ids[]', checkbox.value));

        axios.delete('/blobs/destroy_bulk.json', {
          data: data
        }).then(() => {
          this.hideSelectionActions();
          notyInstance.close();
          this.checked.forEach((checkbox) => {
            const row = checkbox.closest('.c-table__row.pixie-file');
            row.parentNode.removeChild(row);
          });
          infoNotification('Files queued for deletion.');
        }).catch((err) => {
          notyInstance.close();
          Logger.logError(err);
          errorNotification('Error deleting files.');
        });
      }, { id: 'button1', 'data-status': 'ok' }),

      Noty.button('', 'c-dialog__close c-button--icon c-icon--rounded-filled-close--gris-dark c-icon--m', () => {
        notyInstance.close();
      })
    ];

    const notyInstance = new Noty({
      text: message,
      layout: 'modal',
      type: 'modal',
      theme: 'modal',
      modal: true,
      killer: true,
      closeWith: 'button',
      animation: {
        open: null,
        close: null
      },
      callbacks: {
        onShow: function() {
          document.querySelector('.noty_modal').addEventListener('click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
          });
          document.querySelector('.noty_layout').addEventListener('click', function(e) {
            e.stopImmediatePropagation();
            e.stopPropagation();
          });
        }
      },
      buttons: buttons
    }).show();

    return false;
  }

  submitDownloadForm() {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = '/blobs/download_bulk';

    this.checked.forEach((checkbox) => {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = 'ids[]';
      hiddenField.value = checkbox.value;
      form.appendChild(hiddenField);
    });

    const csrf = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    const csrfField = document.createElement('input');
    csrfField.type = 'hidden';
    csrfField.name = 'authenticity_token';
    csrfField.value = csrf;
    form.appendChild(csrfField);

    document.body.appendChild(form);
    form.submit();
  }

  PrefillEmail() {
    const attachments = [];
    let id = 0;
    this.checked.forEach((checkbox) => {
      const blob = JSON.parse(checkbox.dataset.blobJson);
      const filename = blob.filename;
      const key = blob.key;
      const file = new File([blob], filename);

      attachments.push({ id, file, key });
      id++;
    });

    const subject = this.checked.length === 1
      ? 'Sharing a file with you'
      : `Sharing ${this.checked.length} files with you`;

    const emailData = {
      attachments: attachments,
      subject: subject
    };

    window.dispatchEvent(new CustomEvent('emailModalData', { detail: emailData }));
  }

  handleShowRequestSignatureModal() {
    if (this.prefillSignature()) {
      window.dispatchEvent(new CustomEvent('showRequestSignatureModal'));
    }
  }

  prefillSignature() {
    const supportedFormats = ['pdf', 'docx', 'txt', 'png', 'jpg', 'ppt'];
    const attachments = [];
    let unsupportedFormatFound = false;

    this.checked.forEach((checkbox) => {
      const blob = JSON.parse(checkbox.dataset.blobJson);
      const blob_url = checkbox.dataset.blobUrl;
      const file_extension = blob.filename.split('.').pop().toLowerCase();

      if (!supportedFormats.includes(file_extension)) {
        unsupportedFormatFound = true;
        errorNotification(`Unsupported file format: ${file_extension}`);
        return false;
      }

      attachments.push({
        attachment_id: blob.id,
        file_id: blob.id,
        file_name: blob.filename,
        file_task_name: 'Request signature',
        file_upload_date: blob.created_at,
        file_url: blob_url
      });
    });

    if (unsupportedFormatFound) {
      return false;
    }

    const signatureData = {
      attachments: attachments,
      subject: `Signing ${this.checked.length} documents`,
    };

    window.dispatchEvent(new CustomEvent('signatureModalData', { detail: signatureData }));
    return true;
  }

  requestSignature() {
    const supportedFormats = ['pdf', 'docx', 'txt', 'png', 'jpg', 'ppt'];
    const blobIds = [];
    let unsupportedFormatFound = false;

    this.checked.forEach((checkbox) => {
      const blob = JSON.parse(checkbox.dataset.blobJson);
      const fileExtension = blob.filename.split('.').pop().toLowerCase();

      if (!supportedFormats.includes(fileExtension)) {
        unsupportedFormatFound = true;
        errorNotification(`Unsupported file format: ${fileExtension}`);
        return false;
      }

      blobIds.push(blob.id);
    });

    if (unsupportedFormatFound) {
      return false;
    }

    axios.post('tasks', { task: { type: 'Job::TaskRequestSignature' }, blob_ids: blobIds })
      .then(response => {
        if (response.data.redirect_url) {
          window.location.href = response.data.redirect_url;
        } else if (response.data.notice) {
          infoNotification(response.data.notice);
        } else if (response.data.error) {
          errorNotification(response.data.error);
        }
      })
      .catch(error => {
        const errorMessage = error.response?.data?.error || 'ERROR: Could not create signature request';
        errorNotification(errorMessage);
      });

    return true;
  }
}
